import React from 'react';
import PropTypes from 'prop-types';

import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import style from './CookiePolicyPage.module.scss';

const CookiePolicyPage = ({ pageTitle, canonical }) => {
    return (
        <section className={style.CookiePolicyPage}>
            <div className={style.container}>
                <h1 className={style.header}>{pageTitle.text}</h1>
                <iframe
                    height="1111px"
                    width="100%"
                    style="border: 1px solid #d0daeb"
                    data-sp-embed-policy-initial-language="en"
                    onload='!function(e){var t=e.getAttribute("data-sp-embed-policy-initial-language")||"en",i=e.contentWindow,n=document.createElement("script");n.src="https:\/\/cmp.secureprivacy.ai\/sp-embed-policy.js",i.spEmbedData={AdminId:"3214",InitialLanguage:t,Url:window.location.href,PolicyIds:["6641396777d7f06c398517e6"]},i.document.head.appendChild(n)}(this)'
                ></iframe>
                <BreadcrumbsSemanticMarkup
                    pageTitle={pageTitle.text}
                    pageUrl={canonical.text}
                />
            </div>
        </section>
    );
};

CookiePolicyPage.propTypes = {
    pageTitle: PropTypes.object.isRequired,
    canonical: PropTypes.object.isRequired,
};

export default CookiePolicyPage;
